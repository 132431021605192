// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-contact-success-jsx": () => import("./../../../src/pages/ContactSuccess.jsx" /* webpackChunkName: "component---src-pages-contact-success-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-portfolio-jsx": () => import("./../../../src/pages/portfolio.jsx" /* webpackChunkName: "component---src-pages-portfolio-jsx" */),
  "component---src-pages-qualifications-jsx": () => import("./../../../src/pages/qualifications.jsx" /* webpackChunkName: "component---src-pages-qualifications-jsx" */),
  "component---src-pages-skills-jsx": () => import("./../../../src/pages/skills.jsx" /* webpackChunkName: "component---src-pages-skills-jsx" */)
}

